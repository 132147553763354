import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import "../App/App.css"
export default function ForgotPassword() {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="forgot-password-paper">
        <Avatar className="forgot-password-avatar">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <form className="forgot-password-form" noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{ shrink: true }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="forgot-password-submit"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Sign In
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}