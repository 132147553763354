import React from "react";
import { Avatar, Grid, TextField, Button } from "@mui/material";

import profile_img from "../images/John.jpg";
import "../App/App.css"
export default function Account() {
  return (
    <div className="account-page">
      <Avatar src={profile_img} className="account-profile-img" />
      <Grid container spacing={3} alignItems="center" justifyContent="center" direction="column">
        <Grid item xs={12}>
          <TextField
            name="nameField"
            label="Name"
            value="John Voss"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="emailField"
            label="Email"
            value="john.voss@fraunhofer.de"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}