/**
 * @file This file contains the remove component 
 * @author Tom Kollmer 
 */

import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import "../../App/App.css"
/**
 * remove component 
 * @component
 * @param {func} onClick is called when the button is pressed
 * @returns component
 */
export default function Remove(props) {
  const { onClick } = props;
  return (
    <Grid item xs={1} container justifyContent="center">
      <IconButton
        color="secondary"
        onClick={onClick}
        className="formBtn"
        size="large">
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
}
