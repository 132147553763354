/**
 * @file Manages the connection with the backend when new policies are created
 * @author Tom Kollmer
 */

import axios from "axios";
import negotiateTestResponse from "../negotiateExample.json";
import { operator_list, ids_policy_types, odrl_policy_types } from "../controls/InitialFieldListValues";
import validation from "./validation";
/**
 * Main function that is called when a new policy is created. First the inputs are validated then
 * transformed and finally send to the backend.
 * @param {string} url that is used to send the data to the backend
 * @param {object} values that contains all user input
 * @param {object} states that determine all selected components
 * @param {func} setErrors function to show error messages on the UI
 * @param {object} navigate  that controls the currently shown page
 * @param {object} e sender to determine the origin [Not used at the moment]
 */
export default function Submit(url, values, states, setErrors, navigate , e) {
  console.log(values)
  const replacer = (key, value) =>
    value instanceof Object && !(value instanceof Array)
      ? Object.keys(value)
          .sort()
          .reduce((sorted, key) => {
            sorted[key] = value[key];
            return sorted;
          }, {})
      : value;

  if (validation(values, states, setErrors)) {
    for (var key in states) {
      states[key] = false;
    }
    var isoValues = convertDateToIso(values, states);
    if (values.is_template) {
      axios.post("/policy/template", isoValues).then(
        () => {
          values["is_template"] = false
          values["originQuery"] = ""
        }
      );

    } else {
      translateOperatorToLanguage(isoValues)
      axios.post(url, isoValues).then(
        (response) => {
          axios
            .post("/policy/initialTechnologyDependentPolicy", response.data)
            .then(
              (responseTDP) => {
                var dict = {
                  jsonPolicy: JSON.stringify(response.data, replacer, 2),
                  dtPolicy: responseTDP.data,
                };
                dict.language = values.language
                navigate('/ODRLCreator',{state:dict});
              },
              (error) => {
                console.log(error);
              }
            );
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
}

/**
 * Connects with the backend to translate the user input to a valid policy.
 * @param {string} url that is used to send the data to the backend
 * @param {object} values that contains all user input
 * @param {func} setPolicy function to set policy
 */
export function jsonOdrlPolicy(url, values, setPolicy) {
  axios
    .post(url, values, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    })
    .then(
      (response) => {
        setPolicy(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
}

/**
 * [Not used!] Tries to access the negotiated policy and retries if the policy is still stuck in the negotiation process.
 * @param {object} axios object
 * @param {object} options json object with retry_time and retry_status_code
 */
const retryWrapper = (axios, options) => {
  const max_time = options.retry_time;
  const retry_status_code = options.retry_status_code;
  let counter = 0;
  axios.interceptors.response.use(null, (error) => {
    const config = error.config;
    if (counter < max_time && error.response.status === retry_status_code) {
      counter++;
      return new Promise((resolve) => {
        resolve(axios(config));
      });
    }
    // ===== this is mock final one is a successful request, you could delete one in usage.
    if (counter === max_time && error.response.status === retry_status_code) {
      config.url = "https://api.ipify.org?format=json";
      return new Promise((resolve) => {
        resolve(axios(config));
      });
    }
    return Promise.reject(error);
  });
};

/**
 * Tries to get the uuid of the negotiated policy after it is exported.
 * @param {string} url that is used to send the data to the backend
 * @returns {string} the uuid of the negotiated policy
 */
export async function negotiatePolicyGetUUID(url) {
  let uuid = await axios.post(
    `${url}/contract-negotiation`,
    negotiateTestResponse,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return uuid.data;
}

/**
 * Tries to get the negotiated policy with the given uuid
 * @param {string} url that is used to send the data to the backend
 * @param {string} uuid of the negotiated policy
 * @returns {object} negotiated policy
 */
export async function negotiatePolicyGetResponse(url, uuid) {
  let policy = await axios.get(`${url}/${uuid}`);
  return policy;
}


/**
 * Sets policy type according to selected language
 * @param {object} values object with all the user input
 */
 function setPolicyType(values) {
  if (values.language === "IDS") {
    for (const type of ids_policy_types) { 
      if (type.id === values.policyType) {
        values["policyType"] = type.value
      }    
    }
  } else if (values.language === "ODRL"){
    for (const type of odrl_policy_types) { 
      if (type.id === values.policyType) {
        values["policyType"] = type.value
      }    
    }  }
 }
 
/**
 * Converts the date to iso standard
 * @param {object} values object with all the user input
 * @param {object} states object with all the selected states
 * @returns {string} the date in iso format
 */
function convertDateToIso(values, states) {
  var isoValues = { ...values };
  isoValues.specifyBeginTime = addDateSuffix(isoValues.specifyBeginTime);
  isoValues.restrictEndTime = addDateSuffix(isoValues.restrictEndTime);
  isoValues.restrictStartTime = addDateSuffix(isoValues.restrictStartTime);
  isoValues.restrictEndTimeInterval = addDateSuffix(isoValues.restrictEndTimeInterval);
  isoValues.restrictStartTimeInterval = addDateSuffix(isoValues.restrictStartTimeInterval);
  isoValues.timeDate = addDateSuffix(isoValues.timeAndDate);
  return isoValues;
}

/**
 * Converts all operators to ids or odrl operators defined in operator_list
 * @param {object} values object with all the user input
 */
function translateOperatorToLanguage(values) {
  Object.entries(values).forEach(([key, value]) => {  
    if (((value !== "") && (typeof value === 'string')) || value instanceof String){
      operator_list.forEach(function (item, index) {
        if (value  === item.id) {
          values[key] = item[values.language.toLowerCase()]
        }
      });
    }
  })
}
/**
 * Adds a suffix to the date string
 * @param {String} date that should be converted
 * @returns {string} converted date
 */
function addDateSuffix(date) {
  if (date === "" || date === undefined) {
    return "";
  }
  return date + ":00Z";
}
