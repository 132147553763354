/**
 * @file This file contains components that can be used by the pages to show components
 * @author Tom Kollmer 
 */
import React from 'react'
import "../../App/App.css"
/**
 * Basic component for the pages
 * @component
 * @param {object} props additional flags and settings for the form component
 * @returns component
 */
export default function Form(props) {
    const { children, ...other } = props;
    return (
        <form className="root" autoComplete="off" {...other}>
          {props.children}
        </form>
      );
}

