import React, { useState } from "react";
import { Grid, Button, Paper } from "@mui/material";
import PageHeader from "../components/PageHeader";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Input from "../components/controls/Input";
import { useNavigate } from 'react-router-dom';
import Form from "../components/controls/Form";
import IdentifyPolicy from "../components/controls/IdentifyPolicy";
import { OdrlPolicy } from "../components/backend/OdrlPolicy";
import Submit from "../components/backend/Submit";
import Title from "../components/controls/Title";
import { useLocation } from "react-router-dom";
import "../App/App.css"

const selected_components = {
  page: "CountAccess",
};

export default function CountAccess() {
  var initialValues = OdrlPolicy();
  var stateLocal = useLocation().state;

  if (stateLocal !== null) {
    initialValues = stateLocal;
  }

  const valueHook = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useNavigate();


  const handleSubmit = (e) => {
    const values = valueHook[0];
    Submit(
      "/policy/CountAccessPolicyForm",
      values,
      selected_components,
      setErrors,
      history,
      e
    );
  };
  const handleClickSetODRL = (event, index) => {
    const values = valueHook[0];

    values["language"] = "ODRL" 
    handleSubmit();
  };

  const handleClickSetIDS = (event, index) => {
    const values = valueHook[0];

    values["language"] = "IDS" 
    handleSubmit();
  };
  return (
    <div className="page">
      <Form>
        <PageHeader
          title="This policy limits how many times a specific data consumer can use your data."
          icon={<EqualizerIcon />}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={3} className="paperWithoutRemoveBtn">
              <IdentifyPolicy valueHook={valueHook} errors={errors} />

              <Grid container>
                <Title label="Count" />
                <Input
                  name="counter"
                  placeholder="0"
                  valueHook={valueHook}
                  errors={errors}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} xm={1}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#E8F5F2", color: "#239b7e" }}
                  className="saveBtn"
                  onClick={handleClickSetIDS}
                >
                  generate IDS policy
                </Button>
              </Grid>

              <Grid item xs={2} xm={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveBtn"
                  onClick={handleClickSetODRL}
                >
                  generate ODRL policy
                </Button>
              </Grid>

        </Grid>
      </Form>
    </div>
  );
}
