import React, { useState } from "react";
import { Grid, Button, Paper } from "@mui/material";
import PageHeader from "../components/PageHeader";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from 'react-router-dom';
import Form from "../components/controls/Form";
import IdentifyPolicy from "../components/controls/IdentifyPolicy";
import { OdrlPolicy } from "../components/backend/OdrlPolicy";
import Submit from "../components/backend/Submit";
import LogData from "../components/controls/LogData";
import { useLocation } from "react-router-dom";
import Title from "../components/controls/Title";
import "../App/App.css"

const selected_components = {
  page: "LogAccess",
};

export default function LogAccess() {
  var initialValues = OdrlPolicy()
  var stateLocal = useLocation().state;

  if (stateLocal !== null) {
    initialValues = stateLocal;
  }

  const valueHook = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useNavigate();


  const handleSubmit = (e) => {
    const values = valueHook[0]
    Submit(
      "/policy/LogAccessPolicyForm",
      values,
      selected_components,
      setErrors,
      history,
      e
    );
  };
  const handleClickSetODRL = (event, index) => {
    const values = valueHook[0];
    values["language"] = "ODRL"
    handleSubmit();
  };

  const handleClickSetIDS = (event, index) => {
    const values = valueHook[0];
    values["language"] = "IDS"
    handleSubmit();
  };

  return (
    <div className="page">
      <Form>
        <PageHeader
          title="This policy gives permission to a specified data consumer to use your data and requests to log the usage information on a specified system device."
          icon={<AssignmentIcon />}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={3} className="paperWithoutRemoveBtn">
              <IdentifyPolicy
                valueHook={valueHook}
                errors={errors}
              />
              <Grid container>
                <Title label="" />
                <LogData
                  valueHook={valueHook}
                  errors={errors}
                  xs={12}
                  sm={12}
                  md={12}
                  prefix="postduties_"
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#E8F5F2", color: "#239b7e" }}
              className="saveBtn"
              onClick={handleClickSetIDS}
            >
              generate IDS policy
            </Button>
          </Grid>

          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              color="primary"
              className="saveBtn"
              onClick={handleClickSetODRL}
            >
              generate ODRL policy
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
