import React, { useState } from "react";
import { Grid, Button, Paper } from "@mui/material";
import PageHeader from "../components/PageHeader";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import { useNavigate } from 'react-router-dom';
import Form from "../components/controls/Form";
import IdentifyPolicy from "../components/controls/IdentifyPolicy";
import { OdrlPolicy } from "../components/backend/OdrlPolicy";
import Submit from "../components/backend/Submit";
import { useLocation } from "react-router-dom";
import "../App/App.css"
export default function AnonymizeInRest() {
  var initialValues = OdrlPolicy()
  var stateLocal = useLocation().state;
  
  if (stateLocal !== null) {
    initialValues = stateLocal;
  }

  const valueHook = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useNavigate();
  const selected_components = {
    page: "AnonymizeInRest",
  };

  const handleSubmit = (e) => {
    const values = valueHook[0]
    Submit(
      "/policy/AnonymizeInRestPolicyForm",
      values,
      selected_components,
      setErrors,
      history,
      e
    );
  };
  const handleClickSetODRL = (event, index) => {
    const values = valueHook[0];

    values["language"] = "ODRL" 
    handleSubmit();
  };

  const handleClickSetIDS = (event, index) => {
    const values = valueHook[0];

    values["language"] = "IDS" 
    handleSubmit();
  };
  return (
    <div className="page">
      <Form>
        <PageHeader
          title="The assumption is that your data is stored in a database on consumer side.
                This policy requests a specified data consumer to anonymize your data."
          icon={<EnhancedEncryptionIcon />}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={3} className="paperWithoutRemoveBtn">
              <IdentifyPolicy
                valueHook={valueHook}
                errors={errors}
              />
            </Paper>
          </Grid>
          <Grid item xs={2} xm={1}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#E8F5F2", color: "#239b7e" }}
                  className="saveBtn"
                  onClick={handleClickSetIDS}
                >
                  generate IDS policy
                </Button>
              </Grid>

              <Grid item xs={2} xm={1}>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveBtn"
                  onClick={handleClickSetODRL}
                >
                  generate ODRL policy
                </Button>
              </Grid>
        </Grid>
      </Form>
    </div>
  );
}
