import React from "react";
import "./App.css";

import {ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';


import Navigation from "../components/Navigation";

import RouteToPage from "../components/Route";
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#239b7e",
      light: "#E8F5F2",
    },
    secondary: {
      main: "#E96A22",
    },
    background: {
      main: "#F2F2F2",
    },
    text: {
      primary: "#808080",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 100,
        "&:hover": {
          color: "#F7C7AC",
        },
      },
    },
    MuiTypography: {
      subtitle2: {
        color: "#808080",
      },
      h1: {
        color: "#E96A22",
        fontFamily: "FrutigerLTComBold",
        fontSize: "27px",
      },
    },

  },
}));


function App() {
  const [open, setOpen] = React.useState(window.innerWidth > 600);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <CssBaseline />

          <Navigation open={open} setOpen={setOpen} />
          <AppBar position="fixed" />
          <div className="App-content">
              <RouteToPage />
          </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
