import React from "react";
import { Typography, Grid, Icon } from "@mui/material";
import papEditor from "../images/PAP-Editor_Scenario(old).png";
import { loadCSS } from "fg-loadcss";

import "../App/App.css"

export default function HowTo() {
  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  return (
    <div className="how-to-container">
      <div className="how-to-text">
        <Typography variant="h3" className="how-to-title">
          How To
        </Typography>
        <img className="how-to-img" src={papEditor} alt=""></img>
        <Typography variant="body1">
          <li>
            As a Data Provider, you can choose a policy class from the side
            bar of the tool and then, follow the instructions.
          </li>
        </Typography>
        <Typography variant="body1">
          <li>
            By pressing the "Generate ODRL policy" button, you will get the corresponding{" "}
            <a href="https://www.w3.org/TR/odrl-model/" className="how-to-link">
              ODRL
            </a>{" "}
            policy.
          </li>
        </Typography>
        <Typography variant="body1">
          <li>
            In order to get the{" "}
            <a href="https://developer.mydata-control.de/" className="how-to-link">
              MYDATA
            </a>{" "}
            policy, you can press on MYDATA logo.
          </li>
        </Typography>
        <Typography variant="body1">
          <li>
            In addition, you can export the{" "}
            <a href="https://developer.mydata-control.de/" className="how-to-link">
              MYDATA
            </a>{" "}
            policy from this editor.
          </li>
        </Typography>
        <Typography variant="body1">
          <li>
            This {" "}
            <a href="https://developer.mydata-control.de/" className="how-to-link">
              MYDATA
            </a>{" "}
            policy is ready to be imported into the{" "}
            <a href="https://developer.mydata-control.de/" className="how-to-link">
              MYDATA
            </a>{" "}
            PMP (Policy Management Point). You can then deploy the{" "}
            <a href="https://developer.mydata-control.de/" className="how-to-link">
              MYDATA
            </a>{" "}
            policy.
          </li>
        </Typography>
      </div>
      <div className="how-to-infoBox">
        <Grid container spacing={1}>
          <Grid item xs={2} md={1} container justifyContent="center">
            <Icon
              className="how-to-icon fas fa-exclamation"
              style={{ color: "#FFFFFF", fontSize: "5em" }}
            />
          </Grid>
          <Grid item xs={8} md={9}>
            <Typography variant="body1">
              Be aware that deploying{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              policy into the{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              PDP (Policy Decision Point) is not enough for the policy
              enforcement. You still need to create{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              PEP (Policy Enforcement Point) and the needed{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              PIPs (Policy Information Points) and{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              PXPs (Policy Execution Points). When the enforcement point is
              implemented, then you can be assured that your data is technically
              protected. To implement your{" "}
              <a href="https://developer.mydata-control.de/" className="how-to-link">
                MYDATA
              </a>{" "}
              PEP (Policy Enforcement Point), follow the instructions documented
              in{" "}
              <a
                href="https://developer.mydata-control.de/sdk/howto_pep.html"
                className="how-to-link"
              >
                MYDATA Web Page
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs={2} md={1} container></Grid>
        </Grid>
      </div>
    </div>
  );
}