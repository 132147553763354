/**
 * @file This file contains the page header component
 * @author Tom Kollmer 
 */

import React from "react";
import {  Typography } from "@mui/material";


/**
 * Title component that is used on the page to show the page title.
 * @param {string} title for the page
 * @returns the component
 */
export default function PageHeader(props) {
  const { title } = props;
  return (
    <div className="pageHeader">
      
      <div>
        <Typography variant="h5" component="div" id="Title">
        {}{title}
        </Typography>
      </div>
    </div>
  );
}
