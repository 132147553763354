import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import {jsonOdrlPolicy} from "../components/backend/Submit";
import CodeMirror from '@uiw/react-codemirror';
import { materialLight } from '@uiw/codemirror-theme-material';
import { json } from '@codemirror/lang-json';

import '../App/App.css';

const defaultTranslation = `A data provider is an party that is issuing the rule.
Here the provider is myCompany party.
This party is either the Data Owner or the Data Provider
of the specified data asset.
In this Policy OFFER example, the permission rule allows the
Data Consumer to use the target asset.The identifier of this
policy and the target asset are http://example.com/policy/myPolicy
and http://example.com/data/sample, respectively.`;

const defaultPolicy = '{\n\t"@context": [\n\t  "http://www.w3.org/ns/odrl.jsonld",\n\t {\n\t  "dc": "http://purl.org/dc/terms/",\n\t  "ids": "https://w3id.org/idsa/core/",\n\t  "idsc": "https://w3id.org/idsa/code/",\n\t  "ods": "https://w3id.org/ods/"\n\t }\n\t],\n\t"@type": "Offer",\n\t"permission": [\n\t {\n\t  "action": "use",\n\t  "assigner": "http://example.com/party/myCompany",\n\t  "target": "http://example.com/data/sample"\n\t }\n\t],\n\t"profile": "http://example.com/odrl:profile",\n\t"uid": "http://example.com/policy/myPolicy"\n\t}';
export default function InterpretOdrlPolicy() {
  const [policy, setPolicy] = useState(defaultPolicy);
  const [dtPolicy, setDtPolicy] = useState(defaultTranslation);

  const transfer = () => {
    jsonOdrlPolicy("/policy/InterpretOdrlPolicy", policy, setDtPolicy);
  };

  function handleInputChange(e, setValues) {
    setValues(e);
  }

  return (
    <div className="interpret-page">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h5">Copy your ODRL policy here:</Typography>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={policy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              onChange={(e) => {
                handleInputChange(e, setPolicy);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h5">Translation</Typography>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={dtPolicy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              onChange={(e) => {
                handleInputChange(e, setDtPolicy);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            className="interpret-translateBtn"
            variant="contained"
            color="secondary"
            onClick={transfer}
          >
            Translate
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
