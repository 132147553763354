/**
 * @file This file contains components for the policy header.
 * @author Tom Kollmer
 */
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Input from "../controls/Input";

import ItemPicker from "../controls/ItemPicker";
import { policy_types } from "../controls/InitialFieldListValues";
import Title from "../controls/Title";
import { v4 as uuidv4 } from "uuid";
import InputBasic from "./InputBasic";
import InputDisabled from "./InputDisabled";

/**
 * This function defines the header of all policies and is used by all pages that create a new policy.
 * @component
 * @param {object} valueHook access to the user input
 * @param {object} errors contains all error messages
 * @returns component
 */
export default function IdentifyPolicy(props) {
  const { valueHook, errors } = props;
  const values = valueHook[0];
  const [generatedUUID, setGeneratedUUID] = useState({"url": "http://example.com/policy/", "uuid": uuidv4()});


  const handleURLChange = (event) => {
    const enteredURL = event.target.value;
    const updatedID = enteredURL + "/" + generatedUUID.uuid;
    setGeneratedUUID((prevValues)=>({
      ...prevValues,
      url: enteredURL,
    }))
    valueHook[1]((prevValues) => ({
      ...prevValues,
      id: updatedID,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Title label="URL*" separator={false} />
        <InputBasic
          name="url"
          placeholder="http://example.com"
          valueHook={[generatedUUID, setGeneratedUUID]}
          errors={errors}
          xs={12}
          sm={12}
          md={12}
          onChange={handleURLChange}
        />
      </Grid>
      <Grid item xs={4}>
        <Title label="UUID*" separator={false} />
        <InputDisabled
          name="uuid"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          valueHook={[generatedUUID, setGeneratedUUID]}
          errors={errors}
          xs={12}
          sm={12}
          md={12}
          
        />
      </Grid>
      <Grid item xs={4}>
        <Title label="Policy Type*" separator={false} />
        <ItemPicker
          name="policyType"
          defaultValue="Agreement"
          ItemList={policy_types}
          valueHook={valueHook}
          errors={errors}
          xs={12}
          sm={12}
          md={12}
        />
      </Grid>
      {values.policyType === "Agreement" || values.policyType === "Offer" ? (
        <>
          <Grid item xs={6}>
            <Title label="Data Provider*" separator={false} />
            <Input
              name="provider"
              placeholder="My party"
              valueHook={valueHook}
              errors={errors}
              xs={12}
              sm={12}
              md={12}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={6} />
      )}
      {values.policyType === "Agreement" || values.policyType === "Request" ? (
        <>
          <Grid item xs={6}>
            <Title label="Data Consumer*" separator={false} />
            <Input
              name="consumer"
              placeholder=""
              valueHook={valueHook}
              errors={errors}
              xs={12}
              sm={12}
              md={12}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={6} />
      )}

      <Grid item xs={12}>
        <Title label="Data URI*" separator={false} />
        <Input
          name="target"
          placeholder="e.g. http://example.com/ids/data/production-plan"
          valueHook={valueHook}
          errors={errors}
          xs={12}
          sm={12}
          md={12}
        />
      </Grid>
    </Grid>
  );
}
