/**
 * @file This contains the pre duty component
 * @author Tom Kollmer
 */

import React, { useState } from "react";
import { Grid, MenuItem, Menu, Button, Paper, Collapse } from "@mui/material";
import MenuItems from "./MenuItems";
import Title from "./Title";
import Remove from "./Remove";
import { Typography } from "@mui/material";
import Anonymize from "./Anonymize";
import MultiSelectInputFieldNoOperator from "./MultiSelectInputFieldNoOperator";
import { checkIfNotFirstElement } from "../controls/Utils";
import { TransitionGroup } from "react-transition-group";
import "../../App/App.css"
/**
 * Components for the pre duty component
 * @component
 * @param {object} valueHook access to the user input
 * @param {object} errors contains all error messages
 * @param {object} selectedComponents contains all selected components
 * @param {func} removeComponent is called to remove components
 * @param {func} removeEnteredData is called to remove entered data
 * @param {object} classes contains the css definitions
 * @param {string} name that should be used for the components
 * @param {string} title that should be used for the components
 * @param {string} prefix that should be used for the components
 * @returns component
 */
export default function PreDuty(props) {
  const {
    valueHook,
    errors,
    selectedComponents,
    removeComponent,
    removeEnteredData,
    name = "",
    title = "",
    prefix = "",
  } = props;

  const selected_delete_data_components = {
    duration: false,
    timeDate: false,
  };
  const setSelectedDeleteComponents = useState(
    selected_delete_data_components
  )[1];

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const addAll = () => {
    const dict = selectedComponents.availableComponents;
    dict.forEach(function (item) {
      if (!item.isVisible) {
        item.isVisible = true;
        selectedComponents.order.push(item.id);
      }
    });
  };
  function getComponent(key) {
    switch (key) {
      case "obtainConsent":
        return (
          <Grid container key={"obtainConsent" + prefix}>
            <Title
              label="Obtain Consent"
              separator={checkIfNotFirstElement(
                selectedComponents.order,
                "obtainConsent"
              )}
              xs={12}
            />
            <Grid container xs={11} spacing={2}>
              <MultiSelectInputFieldNoOperator
                name="preduties_consentingParty"
                placeholder=""
                valueHook={valueHook}
                errors={errors}
                xs={12}
                sm={12}
                md={12}
              />
            </Grid>
            <Remove
              onClick={() => {
                setSelectedDeleteComponents({
                  duration: false,
                  timeDate: false,
                });
                removeComponent(prefix, "obtainConsent");
                removeEnteredData([prefix + "_consentingParty_input"]);
              }}
            />
          </Grid>
        );
      case "anonymizeTransit":
        return (
          <Grid container key={"anonymizeTransit" + prefix}>
            <Title
              label="Anonymize in Transit"
              separator={checkIfNotFirstElement(
                selectedComponents.order,
                "anonymizeTransit"
              )}
              xs={12}
            />
            <Grid container xs={11} spacing={2}>
              <Anonymize valueHook={valueHook} errors={errors} />
            </Grid>
            <Remove
              onClick={() => {
                setSelectedDeleteComponents({
                  duration: false,
                  timeDate: false,
                });
                removeComponent(prefix, "anonymizeTransit");
                removeEnteredData([
                  prefix + "_modifier",
                  prefix + "_valueToChange",
                  prefix + "_fieldToChange",
                ]);
              }}
            />
          </Grid>
        );
      case "anonymizeInRest":
        const key = prefix + "_anomInRest";
        if (valueHook[0][key] === "") {
          valueHook[0][key] = "Active";
        }
        return (
          <Grid container key={"anonymizeInRest" + prefix}>
            <Title
              label="Anonymize in Rest"
              separator={checkIfNotFirstElement(
                selectedComponents.order,
                "anonymizeInRest"
              )}
              xs={12}
            />
            <Grid container xs={11} spacing={2}>
              <Typography
                variant="h6"
                sx={{ marginLeft: "15px", marginTop: "42px" }}
              >
                {" "}
                Anonymize in Rest activated.{" "}
              </Typography>
            </Grid>
            <Remove
              onClick={() => {
                removeComponent(prefix, "anonymizeInRest");
                removeEnteredData([prefix + "_anomInRest"]);
              }}
            />
          </Grid>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {selectedComponents.order.length > 0 && (
        <>
          <Grid item xs={11} container>
            <Typography
              variant="h5"
              component="div"
              id="subTitle2"
              className="subtitle"
            >
              {title}
            </Typography>
          </Grid>
          <Paper
            elevation={3}
            className="paper"
            style={{ minHeight: 0 }}
          >
            <TransitionGroup>
              {selectedComponents.order.map((item) => (
                <Collapse key={item}>{getComponent(item)}</Collapse>
              ))}
            </TransitionGroup>
          </Paper>
        </>
      )}
      {Object.values(selectedComponents.availableComponents).some(
        (x) => x.isVisible === false
      ) ? (
        <Grid item xs={12} container>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className="addBtn"
              id="Add Component"
            >
              {"Add " + name}
            </Button>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItems
              selectedComponents={selectedComponents}
              setAnchorEl={setAnchorEl}
            />

            <MenuItem
              onClick={() => {
                addAll();
                setAnchorEl(null);
              }}
              id="all"
            >
              All
            </MenuItem>
          </Menu>
        </Grid>
      ) : null}
    </>
  );
}
