import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Paper, Grid, Button, Typography, IconButton } from "@mui/material";
import { jsonOdrlPolicy } from "../components/backend/Submit";
import { useNavigate } from "react-router-dom";
import Input from "../components/controls/Input";
import CodeMirror from "@uiw/react-codemirror";
import { materialLight } from "@uiw/codemirror-theme-material";
import { json } from "@codemirror/lang-json";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import "../App/App.css";

window.onload = function () {
  var reloading = sessionStorage.getItem("jsonPolicy");
  if (reloading === "null2") {
    sessionStorage.removeItem("reloading");
    window.navigate.back();
  }
};

export default function ODRLCreator() {
  const navigate = useNavigate();
  var stateLocal = useLocation().state;
  if (stateLocal === undefined) {
    if (typeof stateLocal === "undefined") {
      sessionStorage.removeItem("jsonPolicy");
      navigate("/");
    }
    stateLocal = {
      jsonPolicy: sessionStorage.getItem("jsonPolicy"),
      dtPolicy: sessionStorage.getItem("dtPolicy"),
      language: sessionStorage.getItem("language"),
    };
  }
  const [policy, setPolicy] = useState(stateLocal.jsonPolicy);
  const [dtPolicy, setDtPolicy] = useState(stateLocal.dtPolicy, null, 2);
  const [values, setValues] = useState({ ucAppUrl: "" });
  const [errors, setErrors] = useState({});
  const [translatedLanguage, setTranslatedLanguage] = useState(null);
  sessionStorage.setItem("jsonPolicy", stateLocal.jsonPolicy);
  sessionStorage.setItem("dtPolicy", stateLocal.dtPolicy);
  sessionStorage.setItem("language", stateLocal.language);

  const transferMydata = () => {
    jsonOdrlPolicy(
      "/policy/JsonOdrlPolicyTranslator/mydata",
      policy,
      setDtPolicy
    );
    setTranslatedLanguage("MYDATA");
  };

  const transferRego = () => {
    jsonOdrlPolicy(
      "/policy/JsonOdrlPolicyTranslator/opa-rego",
      policy,
      setDtPolicy
    );
    setTranslatedLanguage("REGO");
  };

  const send = () => {
    var query = {};
    query.jsonString = policy;
    query.ucAppUrl = values.AppUrl;
    if (isValidUrl(query.ucAppUrl) === "") {
      setErrors({ ...errors, ["AppUrl"]: "" });
      jsonOdrlPolicy("/policy/sendPolicy", query, setDtPolicy);
    } else {
      setErrors({ ...errors, ["AppUrl"]: "Please enter a valid URL" });
    }
  };

  function download(content, fileName, contentType) {
    const a = document.createElement("a");
    const file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function downloadPolicy() {
    download(
      JSON.stringify(JSON.parse(policy), null, 2),
      "policy.json",
      "text/plain"
    );
  }

  function downloadIdsOrRego() {
    if (translatedLanguage === "MYDATA") {
      download(dtPolicy, "policy.xml", "text/plain");
    } else if (translatedLanguage === "REGO") {
      download(dtPolicy, "policy.rego", "text/plain");
    } else {
      download(dtPolicy, "policy.json", "text/plain");
    }
  }

  function isValidUrl(string) {
    if (string === "") return "The field should not be empty";

    try {
      new URL(string);
    } catch (_) {
      return "This is not a valid URI";
    }
    return "";
  }

  function handleInputChange(e, setValues) {
    setValues(e);
  }

  return (
    <div className="odrl-page" style={{ margin: "20px" }}>
      <Grid item container xs={12} spacing={1}>
        <Grid
          item
          xs={5}
          sm={5}
          md={5}
          container
          alignItems="center"
          spacing={1}
        >
          <Input
            name={"AppUrl"}
            label={""}
            placeholder="API URI"
            valueHook={[values, setValues]}
            error={errors}
            xs={11}
            sm={11}
            md={11}
          />
          <IconButton
            className="odrl-sendBtn"
            color="secondary"
            id="Send"
            onClick={send}
          >
            <ForwardToInboxIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}></Grid>

        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper
                style={{
                  borderRadius: "15px",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                <IconButton
                  className="odrl-translateBtn"
                  color="secondary"
                  id="TransferMyData"
                  onClick={transferMydata}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/mydata-logo.png`}
                    alt="Translate to MYDATA"
                    width="120"
                    height="32"
                  />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper
                style={{
                  borderRadius: "15px",
                  padding: "10px",
                  backgroundColor: "white",
                }}
              >
                <IconButton
                  className="odrl-translateBtn"
                  color="secondary"
                  id="TransferRego"
                  onClick={transferRego}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/rego-logo.svg`}
                    alt="Translate to Rego"
                    width="32"
                    height="32"
                  />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} xs={12}>
        <Grid item xs={12} md={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography variant="h5">
                Copy your {stateLocal.language} policy here:
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="secondary"
                onClick={downloadPolicy}
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <SaveAltIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={policy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              onChange={(e) => {
                handleInputChange(e, setPolicy);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h5">
              Technology Dependent Policy{" "}
              {translatedLanguage ? ` (${translatedLanguage})` : null}
            </Typography>
            <IconButton
              color="secondary"
              onClick={downloadIdsOrRego}
              sx={{
                width: 48,
                height: 48,
              }}
            >
              <SaveAltIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <CodeMirror
              value={dtPolicy}
              extensions={[json()]}
              theme={materialLight}
              height="800px"
              lineWrapping={true}
              onChange={(e) => {
                handleInputChange(e, setDtPolicy);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
