/**
 * @file This file contains components for the distribute data page
 * @author Tom Kollmer 
 */
import React, { useState } from "react";
import {
  Grid,
  Button,
  Paper,
  Collapse,
} from "@mui/material";
import Input from "./Input";
import ItemPicker from "./ItemPicker";
import Title from "./Title";
import { artifact_state_list } from "./InitialFieldListValues";
import Remove from "./Remove";
import { TransitionGroup } from 'react-transition-group';
import "../../App/App.css"
/**
 * Components for the distribute data pages
 * @component
 * @param {object} valueHook access to the user input
 * @param {object} errors contains all error messages
 * @param {object} selectedComponents contains all selected components
 * @param {func} removeEnteredData is called to remove entered data
 * @param {func} removeComponent is called to remove components from the UI
  * @returns component
 */
export default function DistributeDataComplex(props) {
  const {
    valueHook,
    errors,
    selectedComponents,
    setSelectedComponents,
    removeEnteredData,
    removeComponent,
  } = props;
  return (
    <>
      <TransitionGroup>
        {selectedComponents.order.map((item) => (
          <Collapse key={item}>
            <Paper elevation={3} className="paper">
              <Grid container key={"distribute"}>
                <Title label="Distribute Data" separator={false} />
                <Grid container sm={11} spacing={2}>
                  <ItemPicker
                    name="artifactState"
                    defaultValue=""
                    label="Artifact State"
                    ItemList={artifact_state_list}
                    valueHook={valueHook}
                    errors={errors}
                  />
                </Grid>
                <Remove
                  onClick={() => {
                    removeComponent("distributeData", "distribute");
                    removeEnteredData(["artifactState", "policy"]);
                  }}
                />

                <Grid container sm={11} spacing={2}>
                  <Input
                    name="policy"
                    label="Policy to be sent to the third party"
                    placeholder="e.g. http://example.com/policy/offer-policy"
                    valueHook={valueHook}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Collapse>
        ))}
      </TransitionGroup>

      {!selectedComponents.availableComponents[0].isVisible ? (
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={5}>
            <Button
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => {
                setSelectedComponents({
                  prefix: "distributeData",
                  order: ["distribute"],
                  availableComponents: [
                    {
                      id: "distribute",
                      name: "Distribute Data",
                      isVisible: true,
                    },
                  ],
                });
              }}
              className="addBtn"
              id="Add Component"
            >
              Add Restriction to Distribute Data
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
