/**
 * @file Here are all paths defined that are used by the UI 
 * @author Tom Kollmer 
 */

import { Routes, Route} from 'react-router-dom'
import React from "react";
import CountAccess from "../pages/CountAccess";
import Home from "../pages/Home";
import AnonymizeInRest from "../pages/AnonymizeInRest";
import ComplexPolicyForm from "../pages/ComplexPolicyForm";
import AnonymizeInTransit from "../pages/AnonymizeInTransit";
import LogAccess from "../pages/LogAccess";
import InformParty from "../pages/InformParty";
import DistributeData from "../pages/DistributeData";
import ObtainConsent from "../pages/ObtainConsent";
import ProvideAccess from "../pages/ProvideAccess";
import DeleteDataAfter from "../pages/DeleteDataAfter";
import InterpretOdrlPolicy from "../pages/InterpretOdrlPolicy";
import ODRLCreator from "../pages/ODRLCreator";
import Account from "../pages/Account";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import HowTo from "../pages/HowTo";
import NegotiatePolicy from "../pages/NegotiatePolicy";
/**
 * Here are all routes to the pages defined. To add a new 
 * page just add a new route and add the page component
 * @returns Router
 */
export default function RouteToPage() {
  return (
    <div className="container">
        <Routes>
       <Route exact path="/" element={<Home/>}></Route>
       <Route exact path="/policy/NegotiatePolicy" element={<NegotiatePolicy/>}></Route>
       <Route exact path="/policy/InterpretOdrlPolicy" element={<InterpretOdrlPolicy/>}></Route>
       <Route exact path="/policy/ComplexPolicyForm" element={<ComplexPolicyForm/>}></Route>
       <Route exact path="/policy/ProvideAccessPolicyForm" element={<ProvideAccess/>}></Route>
       <Route exact path="/policy/CountAccessPolicyForm" element={<CountAccess/>}></Route>
       <Route exact path="/policy/DeleteData" element={<DeleteDataAfter/>}></Route>
       <Route exact path="/policy/AnonymizeInRestPolicyForm" element={<AnonymizeInRest/>}></Route>
       <Route exact path="/policy/AnonymizeInTransitPolicyForm" element={<AnonymizeInTransit/>}></Route>
       <Route exact path="/policy/LogAccessPolicyForm" element={<LogAccess/>}></Route>
       <Route exact path="/policy/InformPolicyForm" element={<InformParty/>}></Route>
       <Route exact path="/policy/DistributeData" element={<DistributeData/>}></Route>
       <Route exact path="/policy/ObtainConsent" element={<ObtainConsent/>}></Route>
       <Route exact path="/ODRLCreator" element={<ODRLCreator/>}></Route>
       <Route exact path="/account" element={<Account/>}></Route>
       <Route exact path="/login" element={<SignIn/>}></Route>
       <Route exact path="/register" element={<SignUp/>}></Route>
       <Route exact path="/ForgotPassword" element={<ForgotPassword/>}></Route>
       <Route exact path="/howto" element={<HowTo/>}></Route>
       </Routes>
       </div>
  );
}
