import React, { useState } from "react";
import { Grid, Button, Paper } from "@mui/material";
import PageHeader from "../components/PageHeader";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useNavigate } from 'react-router-dom';
import Form from "../components/controls/Form";
import IdentifyPolicy from "../components/controls/IdentifyPolicy";
import { OdrlPolicy } from "../components/backend/OdrlPolicy";
import Submit from "../components/backend/Submit";
import InformParty from "../components/controls/InformParty";
import { useLocation } from "react-router-dom";
import Title from "../components/controls/Title";
import "../App/App.css"


const selected_components = {
  page: "InformParty",
};

export default function LogAccess() {
  var initialValues = OdrlPolicy();
  var stateLocal = useLocation().state;

  if (stateLocal !== null) {
    initialValues = stateLocal;
  }

  const valueHook = useState(initialValues);
  const [errors, setErrors] = useState({});
  const history = useNavigate();

  const handleSubmit = (e) => {
    const values = valueHook[0];

    Submit(
      "/policy/InformPolicyForm",
      values,
      selected_components,
      setErrors,
      history,
      e
    );
  };
  const handleClickSetODRL = (event, index) => {
    const values = valueHook[0];

    values["language"] = "ODRL"
    handleSubmit();
  };

  const handleClickSetIDS = (event, index) => {
    const values = valueHook[0];

    values["language"] = "IDS"
    handleSubmit();
  };

  return (
    <div className="page">
      <Form>
        <PageHeader
          title="This policy allows a specified data consumer to use your data and requests notifications on each data usage."
          icon={<NotificationsActiveIcon />}
        />
        <Grid container>
          <Grid item xs={12}>
            <Paper elevation={3} className="paperWithoutRemoveBtn">
              <IdentifyPolicy valueHook={valueHook} errors={errors} />
              <Grid container>
                <Title label="" />
                <InformParty
                  valueHook={valueHook}
                  errors={errors}
                  prefix="postduties_"
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#E8F5F2", color: "#239b7e" }}
              className="saveBtn"
              onClick={handleClickSetIDS}
            >
              generate IDS policy
            </Button>
          </Grid>

          <Grid item xs={2} xm={1}>
            <Button
              variant="contained"
              color="primary"
              className="saveBtn"
              onClick={handleClickSetODRL}
            >
              generate ODRL policy
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
}
